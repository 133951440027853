import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

let fcmToken = 'default'

export default () => {
  if (!Capacitor.isNativePlatform()) {
    return {
      getToken: () => 'FakeWebToken',
      registerNotifications: () => {
        console.info('fake registerNotifications function for web')
      },
      unRegister: () => {
        console.info('fake unRegister function for web')
      },
      addListeners: () => {
        console.info('fake addListeners function for web')
      },
    }
  }

  return {
    getToken: () => fcmToken,
    registerNotifications: async () => {
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
        return false
      }

      await addListeners()
      await PushNotifications.register()
      return true
    },
    permissionIsGranted: async () => {
      const permStatus = await PushNotifications.checkPermissions()
      return permStatus.receive === 'granted'
    },
    // unRegister: async () => {
    //   await PushNotifications.unregister()
    // },
  }
}

async function addListeners() {
  const { updatePrediction } = await usePrediction()

  await PushNotifications.addListener('registration', (token) => {
    console.info('Push Registration token: ', token.value)
    fcmToken = token.value
  })
  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Push Registration error: ', err.error)
  })

  await PushNotifications.addListener(
    'pushNotificationReceived',
    async (notification) => {
      console.log(
        'Push notification received: ',
        JSON.stringify(notification, null, 2),
      )
      const id = notification.data?.id
      const date = notification.data?.date
      const error = notification.data?.error
      if (id && date) await updatePrediction(id, date, error)
    },
  )

  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    async (notification) => {
      console.log(
        'Push notification action performed',
        notification.actionId,
        notification.inputValue,
        JSON.stringify(notification.notification.data, null, 2),
      )
      const id = notification.notification.data?.id
      const date = notification.notification.data?.date
      const error = notification.notification.data?.error
      if (id && date) await updatePrediction(id, date, error)
    },
  )
}
